import { Navigate } from "react-router-dom";
import { useState, useContext, ReactNode, ReactElement } from "react";
import AuthContext from "../context/AuthContext";
import Navbar from "./Navbar";

interface Props {
  children?: ReactNode;
}

const AuthRoute = ({ children, ...props }: Props): any => {
  let { authToken } = useContext(AuthContext);

  return !authToken ? (
    <Navigate to="/login" />
  ) : (
    <div>
      <Navbar />
      <main>{children}</main>
    </div>
  );
};

export default AuthRoute;
