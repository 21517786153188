import React, { useState } from "react";
import { FaUsers, FaNetworkWired, FaKey, FaBars } from "react-icons/fa";
import { FaPeopleRobbery } from "react-icons/fa6";
import { Link, Route, Routes } from "react-router-dom";
import Dashboard from "./sub-pages/Dashboard";
import ManageUsers from "./sub-pages/ManageUsers";
import ManagePNMS from "./sub-pages/ManagePnms";
import ManageRegistrationCodes from "./sub-pages/ManageRegistrationCodes";
import ManageRush from "./sub-pages/ManageRush";

const AdminView: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="min-h-screen flex bg-gray-100">
      <aside
        className={`fixed inset-y-0 left-0 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } w-64 bg-white rounded shadow z-50 transition-transform duration-300 ease-in-out lg:static lg:translate-x-0`}
      >
        <div className="px-4 py-6">
          <h1 className="text-2xl font-semibold text-center">Admin Panel</h1>
          <nav className="mt-8">
            <ul>
              <li className="flex items-center px-4 py-2 hover:bg-gray-200 rounded-md">
                <FaUsers className="mr-3" />
                <Link to="/admin/manage-users">Users</Link>
              </li>
              <li className="flex items-center px-4 py-2 hover:bg-gray-200 rounded-md">
                <FaNetworkWired className="mr-3" />
                <Link to="/admin/manage-pnms">PNMS</Link>
              </li>
              <li className="flex items-center px-4 py-2 hover:bg-gray-200 rounded-md">
                <FaKey className="mr-3" />
                <Link to="/admin/manage-codes">Registration Codes</Link>
              </li>
              <li className="flex items-center px-4 py-2 hover:bg-gray-200 rounded-md">
                <FaPeopleRobbery className="mr-3" />
                <Link to="/admin/manage-rush">Rush</Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>

      <main
        className={`flex-1 p-6 ${
          isSidebarOpen ? "ml-64" : "ml-0"
        } transition-margin duration-300 ease-in-out`}
      >
        <button
          className="text-3xl text-gray-700 lg:hidden"
          onClick={toggleSidebar}
        >
          <FaBars />
        </button>
        <Routes>
          <Route path="" element={<Dashboard />} />
          <Route path="manage-users" element={<ManageUsers />} />
          <Route path="manage-pnms" element={<ManagePNMS />} />
          <Route path="manage-codes" element={<ManageRegistrationCodes />} />
          <Route path="manage-rush" element={<ManageRush />} />
        </Routes>
      </main>
    </div>
  );
};

export default AdminView;
