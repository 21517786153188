import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

// Define the props interface
interface GeedImageProps {
  photo: string;
}

const GeedImage: React.FC<GeedImageProps> = ({ photo }) => {
  return (
    <Zoom>
      <div className="w-full relative bg-gray-300" style={{ height: "60vh" }}>
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center blur"
          style={{ backgroundImage: `url(${photo})` }}
        ></div>

        <img
          src={photo}
          onLoad={() => {}}
          onError={(e) => {}}
          alt=""
          className="relative w-full h-full object-contain rounded-md shadow-md"
        />
      </div>
    </Zoom>
  );
};

export default GeedImage;
