import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../../utils/NotificationProvider";
import {
  cleanEditUserFormErrorKeys,
  displayFormErrors,
} from "../../../../utils/FormValidation";

interface EditUserProps {
  isOpen: boolean;
  onClose: () => void;
  initialUsername: string | null;
  id: number;
}

interface EditUserRequestBody {
  username?: string | undefined;
  password?: string | undefined;
  confirm_password?: string | undefined;
}

const EditUser: React.FC<EditUserProps> = ({
  isOpen,
  onClose,
  initialUsername,
  id,
}) => {
  const [username, setUsername] = useState<string>("none");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    setUsername(initialUsername ? initialUsername : "none");
  }, [initialUsername]);

  const validateForm = (): boolean => {
    let isValid = true;

    if (username === "") {
      displayDangerNotif("Error", "Username cannot be empty.");
      isValid = false;
    }

    if (password !== confirmPassword) {
      displayDangerNotif("Error", "Passwords do not match.");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const reqBody: EditUserRequestBody = {};

    if (username !== initialUsername) {
      reqBody.username = username;
    }

    if (password !== "") {
      reqBody.password = password;
      reqBody.confirm_password = confirmPassword;
    }

    axios
      .put(`/user/edit/${id}/`, reqBody)
      .then((res) => {
        displaySuccessNotif("Success", "User edited successfully!");
        console.log(res);
        onClose();
      })
      .catch((e) => {
        if (e.response.status === 400) {
          displayFormErrors(e, cleanEditUserFormErrorKeys);
        } else {
          displayDangerNotif(
            "Something went wrong!",
            `Error Code: ${e.response.status}`
          );
        }
      });
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white rounded-lg p-6 z-10 w-2/3">
            <h2 className="text-xl font-bold mb-4">Edit User</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="username" className="block font-medium mb-1">
                  Change Username
                </label>
                <input
                  type="text"
                  id="username"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block font-medium mb-1">
                  Change Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password-confirm"
                  className="block font-medium mb-1"
                >
                  Confirm New Password
                </label>
                <input
                  type="password"
                  id="password-confirm"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mr-2"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EditUser;
