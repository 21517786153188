import React, { useState } from "react";
import useComments from "../hooks/useComments";
import { FaTimes } from "react-icons/fa";
import { Geed } from "../../../types/Geed";
import { Comment } from "../../../types/Geed";
import AuthContext from "../../../context/AuthContext";
import { FiSend } from "react-icons/fi";
import { motion } from "framer-motion";

interface CommentSectionProps {
  geed: Geed;
  setGeed: React.Dispatch<React.SetStateAction<Geed | null>>;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  geed,
  setGeed,
}: CommentSectionProps) => {
  const {
    comment,
    setComment,
    isCommenting,
    isLoading,
    error,
    onDeleteComment,
    onComment,
  } = useComments(geed, setGeed);

  const { user } = React.useContext(AuthContext);

  function isUsersComment(comment: Comment) {
    return user && user.username === comment.username;
  }

  return (
    <>
      <div className="border-t border-gray-300 pt-4 overflow-y-auto max-h-[50vh]">
        <h2 className="text-xl font-semibold mb-2">Comments</h2>
        {!isLoading &&
          geed.comments.map((comment: Comment, index: number) => (
            <div
              key={index}
              className="mb-2 bg-gray-100 rounded p-2 relative break-words"
            >
              {comment && isUsersComment(comment) && (
                <div className="absolute top-0 right-0 text-red-500 m-2 cursor-pointer">
                  <FaTimes onClick={() => onDeleteComment(comment.id, index)} />
                </div>
              )}
              <p className="font-bold">{comment && comment.username}</p>
              <p>{comment && comment.body}</p>
            </div>
          ))}
      </div>

      <div className="mt-4">
        <textarea
          className="w-full p-2 border border-gray-300 rounded shadow-sm"
          rows={4}
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 500 }}
          onClick={onComment}
          className="mt-2 bg-red-500 text-white p-2 rounded-full shadow-sm flex items-center justify-center"
          disabled={isCommenting}
        >
          <FiSend size={24} />
        </motion.button>
      </div>
    </>
  );
};

export default CommentSection;
