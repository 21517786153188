import React, { useEffect, useState } from "react";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../../utils/NotificationProvider";
import axios from "axios";
import {
  cleanEditPNMFormErrorKeys,
  displayFormErrors,
  validatePhoneNumber,
} from "../../../../utils/FormValidation";
import { PNM } from "../ManagePnms";

interface EditPNMRequest {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
}

interface EditPNMProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedPNM: PNM) => void;
  selectedPNM: PNM;
}

const EditPNM: React.FC<EditPNMProps> = ({
  isOpen,
  onClose,
  onSave,
  selectedPNM: selectedPNM,
}) => {
  const [firstName, setFirstName] = useState<string>("none");
  const [lastName, setLastName] = useState<string>("none");
  const [phoneNumber, setPhoneNumber] = useState<string>("none");

  useEffect(() => {
    if (!selectedPNM) return;
    setFirstName(selectedPNM.first_name);
    setLastName(selectedPNM.last_name);
    setPhoneNumber(selectedPNM.phone_number);
  }, [selectedPNM]);

  const validateForm = (): boolean => {
    let isValid = true;

    if (!validatePhoneNumber(phoneNumber)) {
      displayDangerNotif("Error", "Invalid Phone Number.");
      isValid = false;
    }
    // Check if first name or last name contain numbers or special characters.
    // But allow spaces.

    if (firstName === "" || lastName === "") {
      displayDangerNotif("Error", "First name or last name cannot be empty.");
      isValid = false;
    }

    if (firstName.match(/[^a-zA-Z ]/g) || lastName.match(/[^a-zA-Z ]/g)) {
      displayDangerNotif(
        "Error",
        "First name or last name cannot contain numbers or special characters."
      );
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const reqBody: EditPNMRequest = {};

    if (firstName !== selectedPNM.first_name) {
      reqBody.first_name = firstName;
    }

    if (lastName !== selectedPNM.last_name) {
      reqBody.last_name = lastName;
    }

    if (phoneNumber !== selectedPNM.phone_number) {
      reqBody.phone_number = phoneNumber;
    }

    if (Object.keys(reqBody).length === 0) {
      onClose();
      return;
    }

    axios
      .put(`/pnm/edit/${selectedPNM.id}/`, reqBody)
      .then((res) => {
        displaySuccessNotif("Success", "User edited successfully!");
        const updatedPNM = {
          ...selectedPNM,
          ...res.data,
        };
        onSave(updatedPNM);
      })
      .catch((e: any) => {
        try {
          if (e.response.status === 400) {
            displayFormErrors(e, cleanEditPNMFormErrorKeys);
          } else {
            displayDangerNotif(
              "Something went wrong!",
              `Error Code: ${e.response.status}`
            );
          }
        } catch (error) {
          console.error(error);
        }
      });
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white rounded-lg p-6 z-10 w-2/3">
            <h2 className="text-xl font-bold mb-4">Edit User</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="firstname" className="block font-medium mb-1">
                  Change Firstname
                </label>
                <input
                  type="text"
                  id="firstname"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="lastname" className="block font-medium mb-1">
                  Change Lastname
                </label>
                <input
                  type="text"
                  id="lastname"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="phonenumber" className="block font-medium mb-1">
                  Change Phone Number
                </label>
                <input
                  type="text"
                  id="phonenumber"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mr-2"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPNM;
