import axios from "axios";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../../utils/NotificationProvider";
import ButtonLoading from "../../../../components/ButtonLoading";

interface CreateRegistrationCodeModalProps {
  isOpen: boolean;
  onCreate: (code: string, clearCode: () => void) => void;
  onCancel: () => void;
}

const CreateRegistrationCodeModal: React.FC<
  CreateRegistrationCodeModalProps
> = ({ isOpen, onCancel, onCreate }) => {
  const [code, setCode] = useState<string>("");
  const [isCreatingCode, setIsCreatingCode] = useState<boolean>(false);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white rounded-lg p-6 z-10 w-2/3">
            <h2 className="text-xl font-bold mb-4">
              Create New Registration Code
            </h2>
            <form>
              <div className="mb-4">
                <label htmlFor="code" className="block font-medium mb-1">
                  New Code
                </label>
                <input
                  type="text"
                  id="code"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mr-2"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </button>
                {!isCreatingCode ? (
                  <button
                    type="button"
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={() => onCreate(code, () => setCode(""))}
                  >
                    Save
                  </button>
                ) : (
                  <ButtonLoading />
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateRegistrationCodeModal;
