import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { GeedsContext } from "../../../context/GeedContext";
import OrderBy from "../enums/OrderBy";
import { displayDangerNotif } from "../../../utils/NotificationProvider";
import { GeedPreview } from "../../../types/Geed";

const LAZY_LOAD_AMOUNT = 25;

const useGeeds = () => {
  const startingOrderBy = localStorage.getItem("orderBy") || OrderBy.default;
  const startingIsShowDropped =
    localStorage.getItem("isShowDropped") === "true";

  const [isShowDropped, setIsShowDropped] = useState(startingIsShowDropped);
  const [searchTerm, setSearchTerm] = useState(getSavedSearchTerm);
  const [orderBy, setOrderBy] = useState(startingOrderBy);
  const [previews, setPreviews] = useState<GeedPreview[]>(getSavedPreviews);
  const [currentIndex, setCurrentIndex] = useState(getSavedIndex);
  const [isFetchingPreview, setIsFetchingPreview] = useState(false);

  const navigate = useNavigate();
  const { geeds, populateGeeds, isLoading } = useContext(GeedsContext);

  const prevOrderby = useRef(orderBy);
  const prevIsDropped = useRef(isShowDropped);
  const prevSearchFilter = useRef(searchTerm);

  function getSavedPreviews(): GeedPreview[] {
    const savedPreviews = sessionStorage.getItem("previews");
    return savedPreviews ? JSON.parse(savedPreviews) : [];
  }

  function getSavedIndex(): number {
    const savedIndex = sessionStorage.getItem("currentIndex");
    return savedIndex ? JSON.parse(savedIndex) : 0;
  }

  function getSavedSearchTerm(): string {
    const savedSearchTerm = sessionStorage.getItem("searchTerm");
    return savedSearchTerm ? savedSearchTerm : "";
  }

  function isQueryChanged(): boolean {
    return (
      prevOrderby.current !== orderBy ||
      prevIsDropped.current !== isShowDropped ||
      prevSearchFilter.current !== searchTerm
    );
  }

  const fetchMorePreviews = () => {
    if (!geeds || isFetchingPreview) return;

    setIsFetchingPreview(true);

    if (currentIndex < geeds.length) {
      const nextIds = geeds.slice(
        currentIndex,
        currentIndex + LAZY_LOAD_AMOUNT
      );

      axios
        .post(`/geeds/previews/`, { ids: nextIds })
        .then((response) => {
          setCurrentIndex((prevIndex) => prevIndex + nextIds.length);
          setPreviews((prev) => [...prev, ...response.data]);
        })
        .catch(() =>
          displayDangerNotif(
            "Error",
            "There was an error loading more PNMs. Try refreshing?"
          )
        )
        .finally(() => setIsFetchingPreview(false));
    }
  };

  // Populate geeds if empty.
  useEffect(() => {
    if (geeds === null) {
      populateGeeds(orderBy, isShowDropped, searchTerm);
    }
  }, []);

  // Handle query changes appropriately.
  useEffect(() => {
    if (!isQueryChanged()) return;

    populateGeeds(orderBy, isShowDropped, searchTerm);

    setPreviews([]);
    setCurrentIndex(0);
    prevOrderby.current = orderBy;
    prevIsDropped.current = isShowDropped;
    prevSearchFilter.current = searchTerm;
  }, [orderBy, isShowDropped, searchTerm]);

  useEffect(() => {
    const scrollY = sessionStorage.getItem("scrollY");
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY));
    }
  }, []);

  const viewGeed = (id: number) => {
    // This will be cleared if populateGeeds is called anywhere!
    sessionStorage.setItem("scrollY", window.scrollY.toString());
    sessionStorage.setItem("previews", JSON.stringify(previews));
    sessionStorage.setItem("currentIndex", currentIndex.toString());
    sessionStorage.setItem("searchTerm", searchTerm);

    navigate(
      `/geedview/${isShowDropped ? "dropped" : "pnm"}/${orderBy}/${id}`,
      { replace: false }
    );
  };

  return {
    isShowDropped,
    setIsShowDropped,
    searchTerm,
    setSearchTerm,
    orderBy,
    setOrderBy,
    navigate,
    geeds,
    populateGeeds,
    isLoading,
    viewGeed,
    fetchMorePreviews,
    currentIndex,
    previews,
    isFetchingPreview,
  };
};

export default useGeeds;
