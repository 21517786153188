import { useState, useRef } from "react";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../utils/NotificationProvider";
import imageCompression from "browser-image-compression";
import axios from "axios";
import {
  cleanEntryFormErrorKeys,
  displayFormErrors,
  validatePhoneNumber,
} from "../../../utils/FormValidation";
import { SCHOOL_YEAR_TYPES } from "../../../types/Geed";
import CONSTANTS from "../../../utils/constants";
import toast from "react-hot-toast";

const useEntryForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [photo, setPhoto] = useState<File | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [schoolYear, setSchoolYear] = useState<SCHOOL_YEAR_TYPES>("1");
  const [major, setMajor] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const getCleanPhoneNumber = () => {
    return phoneNumber.replace(/\D/g, "");
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setMajor("");
    setSchoolYear("1");
    setPhoto(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Check if any files were selected
    if (event.target.files && event.target.files.length > 0) {
      // Get the first file (which is the one selected by the user)
      const file = event.target.files[0];

      // Update the photo state with the new file
      setPhoto(file);
    }
  };

  const validateForm = (): boolean => {
    if (!photo) {
      displayDangerNotif("Error", "No Photo.");
      return false;
    }

    if (!firstName) {
      displayDangerNotif("Error", "No First Name.");
      return false;
    }

    if (!lastName) {
      displayDangerNotif("Error", "No Last Name.");
      return false;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      displayDangerNotif("Error", "Not a valid phone number.");
      return false;
    }

    if (!major) {
      displayDangerNotif("Error", "No Major.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) return;

      setIsCreating(true);

      // Compress the image here
      let compressedBlob = photo;
      if (photo) {
        const options = {
          maxSizeMB: CONSTANTS.IMAGE_SIZE_MB,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        compressedBlob = await imageCompression(photo, options);
      }

      const compressedPhoto = new File([compressedBlob!], photo!.name, {
        type: compressedBlob!.type,
        lastModified: new Date().getTime(),
      });

      const formData = new FormData();

      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("phone_number", getCleanPhoneNumber());
      formData.append("photo", compressedPhoto!);
      formData.append("school_year", schoolYear);
      formData.append("major", major);

      const createRequest = axios.post("/pnms/create/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const toastRequest = await toast.promise(createRequest, {
        loading: `Creating ${firstName} ${lastName}`,
        success: `Created ${firstName} ${lastName}.`,
        error: "Error creating PNM.",
      });

      resetForm();
    } catch (e: any) {
      if (e.response.status === 400) {
        displayFormErrors(e, cleanEntryFormErrorKeys);
        return;
      }
      displayDangerNotif("Error", e.response.status);
      console.error(e.response);
    } finally {
      setIsCreating(false);
    }
  };

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    isCreating,
    fileInputRef,
    handleImageChange,
    handleSubmit,
    schoolYear,
    setSchoolYear,
    major,
    setMajor,
  };
};
export default useEntryForm;
