import { displayDangerNotif } from "./NotificationProvider";

/**
 * Validates a phone number.
 * @param phoneNumber - The phone number to validate.
 * @returns True if the phone number is valid, false otherwise.
 */
export const validatePhoneNumber = (phoneNumber: string) => {
  var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
  var digits = phoneNumber.replace(/\D/g, "");
  return phoneRe.test(digits);
};

export const cleanEntryFormErrorKeys = (key: string) => {
  const lookup: { [key: string]: string } = {
    first_name: "First Name",
    last_name: "Last Name",
    phone_number: "Phone Number",
  };
  return lookup[key] || key;
};

export const cleanCommentFormErrorKeys = (key: string) => {
  const lookup: { [key: string]: string } = {
    body: "Comment",
  };
  return lookup[key] || key;
};

export const cleanEditUserFormErrorKeys = (key: string) => {
  const lookup: { [key: string]: string } = {
    username: "Username",
    password: "Password",
    confirm_password: "Confirm Password",
  };
  return lookup[key] || key;
};

export const cleanEditPNMFormErrorKeys = (key: string) => {
  const lookup: { [key: string]: string } = {
    first_name: "First Name",
    last_name: "Last Name",
    phone_number: "Phone Number",
  };
  return lookup[key] || key;
};

export const cleanRegisterFormErrorKeys = (key: string) => {
  const lookup: { [key: string]: string } = {
    username: "Username",
    password: "Password",
    code: "Registration Code",
  };
  return lookup[key] || key;
}

/**
 * Displays form errors by iterating through the error object and calling the displayDangerNotif function for each error message.
 * @param error - The error object containing the response data.
 */
export const displayFormErrors = (
  error: any,
  cleanFunction = (key: string) => key
) => {
  if (error.response.status !== 400) {
    throw new Error(
      "Tried to display form errors when the response status was not 400."
    );
  }
  Object.keys(error.response.data).forEach((key) => {
    const messages = error.response.data[key];
    messages.forEach((message: string) => {
      displayDangerNotif(cleanFunction(key), message);
    });
  });
};
