import { createContext, useContext, useState } from "react";
import { ReactNode, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../utils/constants";
import Loading from "../components/Loading";
import * as Sentry from "@sentry/browser";
import { ConfigContext } from "./ConfigContext";

interface User {
  id: number;
  username: string;
  isSuperUser: boolean;
}

export enum AuthResponse {
  AUTHENTICATED,
  INVALIDCREDENTIALS,
  ERROR,
}

interface AuthContextInterface {
  authToken: string | null;
  loginUser: (username: string, password: string) => Promise<AuthResponse>;
  logoutUser: () => Promise<boolean>;
  user: User | null;
}

const noAuth: AuthContextInterface = {
  authToken: null,
  loginUser: async (username: string, password: string) => {
    return AuthResponse.INVALIDCREDENTIALS;
  },
  logoutUser: async () => {
    return false;
  },
  user: null,
};

const AuthContext = createContext<AuthContextInterface>(noAuth);

export default AuthContext;

interface Props {
  children?: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem("authToken")
  );
  let [user, setUser] = useState<User | null>(null);
  const config = useContext(ConfigContext);

  const navigate = useNavigate();

  /**
   * Configures the interceptors for handling axios responses.
   * If the response status is 401, it navigates to the login page.
   * If the response status is 400, it displays danger notifications for each error message.
   * @returns {void}
   */
  function configureInterceptors() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) navigate("/login");
        return Promise.reject(error);
      }
    );
  }

  function configureAPi() {
    axios.defaults.baseURL = config.apiUrl;
    console.log("API URL: ", config.apiUrl)
    axios.defaults.headers.post["Content-Type"] = "application/json";
    configureInterceptors();
  }

  configureAPi();

  const configureAxiosAuth = (token: string) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  const logoutUser = async () => {
    localStorage.removeItem("authToken");
    delete axios.defaults.headers.common["Authorization"];
    return false;
  };

  useEffect(() => {
    if (authToken) {
      configureAxiosAuth(authToken);

      axios.get("/user/info/").then((response) => {
        setUser(response.data as User);
        Sentry.setUser({ username: response.data.username });
      });
    }

    setIsLoading(false);
  }, [authToken]);

  let loginUser = async (
    username: string,
    password: string
  ): Promise<AuthResponse> => {
    try {
      const response = await axios.post("/token/", {
        username: username,
        password: password,
      });

      if (response.data && response.data.access) {
        const token = response.data.access;
        configureAxiosAuth(token);
        setAuthToken(token);
        localStorage.setItem("authToken", token);

        return AuthResponse.AUTHENTICATED;
      }
      return AuthResponse.ERROR;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        return AuthResponse.INVALIDCREDENTIALS;
      }
      return AuthResponse.ERROR;
    }
  };

  let contextData = {
    authToken: authToken,
    loginUser: loginUser,
    logoutUser: logoutUser,
    user: user,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {!isLoading ? children : <Loading />}
    </AuthContext.Provider>
  );
};
