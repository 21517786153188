import axios from "axios";
import React, { useEffect, useState } from "react";
import { displayDangerNotif } from "../../../utils/NotificationProvider";
import ManageItem, { ItemPreview } from "./components/ManageItem";
import EditPNM from "./components/EditPNM";

export interface PNM {
  first_name: string;
  last_name: string;
  timestamp: string;
  id: number;
  phone_number: string;
}

const ManagePNMS: React.FC = () => {
  const [currentEditPNM, setCurrentEditPNM] = useState<PNM | null>(null);
  const [isEditingPNM, setIsEditingPNM] = useState(false);
  const [pnms, setPNMS] = useState<PNM[]>([]);
  const [itemPreviews, setItemPreviews] = useState<ItemPreview[]>([]);

  useEffect(() => {
    axios
      .get("/pnms/get/")
      .then((res) => {
        setPNMS(res.data);
        setItemPreviews(convertPNMsToItemPreviews(res.data));
      })
      .catch((err) => {
        displayDangerNotif("Error", "Failed to fetch items.");
      });
  }, []);

  const convertPNMsToItemPreviews = (items: PNM[]): ItemPreview[] => {
    return items.map((item) => {
      const { first_name, last_name, ...otherProps } = item;
      return {
        name: `${first_name} ${last_name}`,
        selected: false,
        ...otherProps,
      };
    });
  };

  const handleEditPNM = (user: ItemPreview) => {
    const pnm = pnms.find((pnm) => pnm.id === user.id);
    setCurrentEditPNM(pnm!);
    setIsEditingPNM(true);
  };

  const handleSavedPNM = (updatedPNM: PNM) => {
    setPNMS((prevPNMS) => {
      const updatedPNMS = prevPNMS.map((pnm) =>
        pnm.id === updatedPNM.id ? updatedPNM : pnm
      );

      setItemPreviews((prevItemPreviews) => {
        return prevItemPreviews.map((itemPreview) =>
          itemPreview.id === updatedPNM.id
            ? {
                ...itemPreview,
                name: `${updatedPNM.first_name} ${updatedPNM.last_name}`,
              }
            : itemPreview
        );
      });

      return updatedPNMS;
    });

    setIsEditingPNM(false);
  };

  return (
    <div className="">
      <EditPNM
        isOpen={isEditingPNM}
        onClose={() => setIsEditingPNM(false)}
        onSave={handleSavedPNM}
        selectedPNM={currentEditPNM!}
      />
      <ManageItem
        title="Manage PNMS"
        items={itemPreviews}
        setItems={setItemPreviews}
        deleteItemEndpoint="/pnms/delete/"
        handleEditSelectedItem={handleEditPNM}
      />
    </div>
  );
};

export default ManagePNMS;
