import React from 'react';

const ModalLoader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 opacity-50 z-50">
            <div className="w-12 h-12 border-t-4 border-red-500 border-solid rounded-full animate-spin"></div>
        </div>
    );
};

export default ModalLoader;