enum OrderBy {
  default = "default",
  likesIncreasing = "likes-increasing",
  likesDecreasing = "likes-decreasing",
  dislikesIncreasing = "dislikes-increasing",
  dislikesDecreasing = "dislikes-decreasing",
  timeDecreasing = "time-decreasing",
}

export default OrderBy;
