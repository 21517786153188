import { motion } from "framer-motion";
import React, { ReactNode } from "react";

interface AnimatedModalProps {
  children: ReactNode;
  className?: string;
}

const AnimatedModal: React.FC<AnimatedModalProps> = ({
  children,
  className,
}) => {
  const springAnimation = {
    initial: { scale: 0 },
    animate: { scale: 1 },
    transition: {
      type: "spring",
      stiffness: 700,
      damping: 30,
    },
  };

  return (
    <motion.div
      initial={springAnimation.initial}
      animate={springAnimation.animate}
      transition={springAnimation.transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedModal;
