import React, { useContext } from "react";
import "animate.css/animate.min.css";
import ButtonLoading from "../../components/ButtonLoading";
import useEntryForm from "./hooks/useEntryForm";
import { SCHOOL_YEAR_TYPES } from "../../types/Geed";
import { ConfigContext } from "../../context/ConfigContext";

const EntryView: React.FC = () => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    isCreating,
    fileInputRef,
    handleImageChange,
    handleSubmit,
    schoolYear,
    setSchoolYear,
    major,
    setMajor,
  } = useEntryForm();

  const schoolYearOptions: SCHOOL_YEAR_TYPES[] = ["1", "2", "3", "4"];
  const config = useContext(ConfigContext);

  return (
    <div className="flex flex-col items-center mt-10">
      <div className="text-4xl font-bold mb-4 text-red-500">{config.textLogo}</div>
      <input
        type="text"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        className="p-4 mb-4 bg-white border rounded border-gray-300 placeholder-gray-500 text-center w-80 shadow-md"
      />
      <input
        type="text"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        className="p-4 mb-4 bg-white border rounded border-gray-300 placeholder-gray-500 text-center w-80 shadow-md"
      />
      <input
        type="tel"
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        className="p-4 mb-4 bg-white border rounded border-gray-300 placeholder-gray-500 text-center w-80 shadow-md"
      />
      <select
        value={schoolYear}
        onChange={(e) => setSchoolYear(e.target.value as SCHOOL_YEAR_TYPES)}
        className="p-4 mb-4 bg-white border rounded border-gray-300 text-center w-80 shadow-md"
      >
        {schoolYearOptions.map((year) => (
          <option key={year} value={year}>
            Year {year}
          </option>
        ))}
      </select>
      <input
        type="text"
        placeholder="Major"
        value={major}
        onChange={(e) => setMajor(e.target.value)}
        className="p-4 mb-4 bg-white border rounded border-gray-300 placeholder-gray-500 text-center w-80 shadow-md"
      />
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageChange}
        className="p-4 mb-4 bg-white border rounded border-gray-300 text-center w-80 shadow-md file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-red-50 file:text-red-700 hover:file:bg-red-100"
        ref={fileInputRef}
      />
      {!isCreating && (
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mt-4 rounded"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default EntryView;
