import React, { useState } from "react";
import { Link } from "react-router-dom";
import CONSTANTS from "../utils/constants";
import { FaUserPlus, FaSignOutAlt } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import AuthContext from "../context/AuthContext";
import { ConfigContext } from "../context/ConfigContext";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user } = React.useContext(AuthContext);
  const config = React.useContext(ConfigContext);

  const navLinks = [
    {
      name: "Entry",
      path: "/entry",
      icon: <FaUserPlus size={25} />,
      needsAdmin: false,
    },
    {
      name: "Admin Panel",
      path: "/admin",
      icon: <MdAdminPanelSettings size={25} />,
      needsAdmin: true,
    },
    {
      name: "Logout",
      path: "/login",
      icon: <FaSignOutAlt size={25} />,
      needsAdmin: false,
    },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="container mx-auto flex justify-between items-center py-3 px-6">
        <div className="flex items-center">
          <Link to="/" className="text-xl font-bold text-red-600">
            {config.textLogo}
          </Link>
        </div>
        <div className={`hidden md:flex items-center space-x-4`}>
          {navLinks.map((link) =>
            (link.needsAdmin && user && user.isSuperUser) ||
            !link.needsAdmin ? (
              <Link
                key={link.name}
                to={link.path}
                className="text-gray-800 hover:text-red-600 transition duration-300"
              >
                {link.icon}
              </Link>
            ) : null
          )}
          {user && <span className="text-gray-800">{user.username}</span>}
        </div>
        <div className="md:hidden flex items-center">
          <button
            className="outline-none mobile-menu-button"
            onClick={toggleMobileMenu}
          >
            <svg
              className="w-6 h-6 text-gray-500 hover:text-red-600"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      <div className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}>
        {user && (
          <span className="block py-2 px-4 text-sm">
            <b>{user.username}</b>
          </span>
        )}
        {navLinks.map((link) =>
          !link.needsAdmin || (link.needsAdmin && user && user.isSuperUser) ? (
            <Link
              key={link.name}
              to={link.path}
              className="block py-2 px-4 text-sm hover:bg-gray-200"
            >
              {link.name}
            </Link>
          ) : null
        )}
      </div>
    </nav>
  );
};

export default Navbar;
