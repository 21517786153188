import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import { displayDangerNotif } from "../../../utils/NotificationProvider";
import { AuthResponse } from "../../../context/AuthContext";

const useLogin = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { loginUser, logoutUser } = React.useContext(AuthContext);

  useEffect(() => {
    logoutUser();
  }, []);

  const login = async (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();
    const authResponse = await loginUser(username, password);

    switch (authResponse) {
      case AuthResponse.AUTHENTICATED:
        break;
      case AuthResponse.INVALIDCREDENTIALS:
        setIsLoading(false);
        displayDangerNotif("Invalid Login", "Please try again.");
        return;
      case AuthResponse.ERROR:
        setIsLoading(false);
        displayDangerNotif("Error", "There was an error logging in.");
        return;
    }

    setIsLoading(false);
    navigate("/");
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    isLoading,
    login,
  };
};

export default useLogin;
