import { useState, useEffect, useCallback } from "react";
import { displayDangerNotif } from "../../../utils/NotificationProvider";
import axios from "axios";
import { Geed } from "../../../types/Geed";
import { Comment } from "../../../types/Geed";
import { cleanCommentFormErrorKeys, displayFormErrors } from "../../../utils/FormValidation";

const useComments = (
  geed: Geed,
  setGeed: React.Dispatch<React.SetStateAction<Geed | null>>
) => {
  const [comment, setComment] = useState("");
  const [isCommenting, setIsCommenting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onComment = useCallback(async () => {
    if (comment.length === 0) {
      displayDangerNotif("Error", "Comment cannot be empty.");
      return;
    }

    setIsCommenting(true);

    try {
      const response = await axios.post("/comments/create/", {
        pnm_id: geed.id,
        body: comment,
      });
      const newComments = [...geed.comments];
      newComments.push(response.data);
      setGeed((prevGeed: Geed | null) => ({
        ...prevGeed!,
        comments: newComments,
      }));
      setComment("");
    } catch (e: any) {
      console.log(e);
      if (e.response.status === 400) {
        displayFormErrors(e, cleanCommentFormErrorKeys);
        return;
      }
      displayDangerNotif("Error", "Something went wrong when commenting.");
    } finally {
      setIsCommenting(false);
    }
  }, [geed.id, comment, geed.comments]);

  const onDeleteComment = useCallback(
    async (commentId: number, index: number) => {
      try {
        const response = await axios.delete(
          `/comments/delete/${commentId}/`,
          {}
        );
        const newComments = [...geed.comments];
        newComments.splice(index, 1);
        setGeed((prevGeed: Geed | null) => ({
          ...prevGeed!,
          comments: newComments,
        }));
      } catch (e) {
        displayDangerNotif("Error", "Something went wrong when deleting.");
        console.error(e);
      }
    },
    [geed.comments]
  );

  return {
    comment,
    setComment,
    isLoading,
    isCommenting,
    error,
    onComment,
    onDeleteComment,
  };
};

export default useComments;
