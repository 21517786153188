import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaEdit } from "react-icons/fa";
import Loading from "../../../../components/Loading";
import ConfirmDialog from "../../../../components/ConfirmDialogue";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../../utils/NotificationProvider";
import EditUser from "./EditUser";
import axios from "axios";
import Fuse from "fuse.js";

export interface ItemPreview {
  id: number;
  name: string;
  timestamp: string;
  selected: boolean;
}

interface ManageItemsProps {
  title: string;
  items: ItemPreview[];
  setItems: React.Dispatch<React.SetStateAction<ItemPreview[]>>;
  deleteItemEndpoint: string;
  handleEditSelectedItem: (item: ItemPreview) => void;
}

const ItemCard: React.FC<{
  item: ItemPreview;
  handleItemSelect: (itemId: number) => void;
}> = ({ item, handleItemSelect }) => (
  <div
    key={item.id}
    className={`p-4 rounded shadow flex items-center justify-between cursor-pointer ${
      item.selected ? "bg-blue-200" : "bg-white"
    }`}
    onClick={() => handleItemSelect(item.id)}
  >
    <span>{item.name}</span>
    <small>{new Date(item.timestamp).toLocaleDateString()}</small>
  </div>
);

const ManageItems: React.FC<ManageItemsProps> = ({
  title,
  items,
  setItems,
  deleteItemEndpoint,
  handleEditSelectedItem,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  useEffect(() => {
    if (selectedItems.length === 1) {
      // TODO: Add logic for handling single item selection
    }
  }, [selectedItems]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleItemSelect = (itemId: number) => {
    setItems((prevItems) => {
      if (prevItems) {
        const updatedItems = prevItems.map((item) =>
          item.id === itemId ? { ...item, selected: !item.selected } : item
        );
        const selectedItemIds = updatedItems
          .filter((item) => item.selected)
          .map((item) => item.id);
        setSelectedItems(selectedItemIds);
        return updatedItems;
      }
      return prevItems;
    });
  };

  const handleDeleteSelectedItems = () => {
    axios
      .post(deleteItemEndpoint, { ids: selectedItems })
      .then((res) => {
        setItems((prevItems) => {
          const updatedItems = prevItems!.filter(
            (item) => !selectedItems.includes(item.id)
          );
          setSelectedItems([]);
          setIsConfirmingDelete(false);
          displaySuccessNotif("Success", "Successfully deleted.");
          return updatedItems;
        });
      })
      .catch((err) => {
        displayDangerNotif("Error", "Failed to delete items.");
      });
  };

  const FuseOptions = {
    keys: ["name"],
  };

  const filteredItems =
    searchTerm.length !== 0
      ? new Fuse(items, FuseOptions)
          .search(searchTerm)
          .map((result) => result.item)
      : items;

  const onEditItem = () => {
    const item = items?.find((item) => item.id === selectedItems[0]);
    handleEditSelectedItem(item!);
  };

  return items ? (
    <div className="">
      <ConfirmDialog
        title={"Delete Items"}
        message={`Are you sure you want to delete ${selectedItems.length} items?`}
        isOpen={isConfirmingDelete}
        onConfirm={handleDeleteSelectedItems}
        onCancel={() => setIsConfirmingDelete(false)}
      />

      <div
        className={`fixed top-1/20 left-1/2 transform -translate-x-1/2 z-10 flex space-x-3 ${
          selectedItems.length > 0 ? "bg-gray-50 shadow-sm" : ""
        } rounded-md p-3`}
      >
        {selectedItems.length > 0 && (
          <>
            <button
              className="flex items-center justify-center bg-transparent hover:bg-gray-300 p-2 rounded-full transition duration-200 ease-in-out transform hover:scale-105"
              onClick={() => setIsConfirmingDelete(true)}
              title="Delete"
            >
              <FaTrash color="gray" size={16} />
            </button>
            {selectedItems.length === 1 && (
              <button
                className="flex items-center justify-center bg-transparent hover:bg-gray-300 p-2 rounded-full transition duration-200 ease-in-out transform hover:scale-105"
                onClick={onEditItem}
                title="Edit"
              >
                <FaEdit color="gray" size={16} />
              </button>
            )}
          </>
        )}
      </div>

      <div className="pt-12">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">{title}</h1>
          <div className="relative">
            <input
              type="text"
              className="h-10 pl-10 pr-4 rounded shadow focus:outline-none focus:ring-2 focus:ring-red-600"
              placeholder="Search items..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FaSearch className="w-4 h-4 absolute left-3 top-3 text-gray-500" />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredItems.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              handleItemSelect={handleItemSelect}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default ManageItems;
