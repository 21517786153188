import React, { createContext, useState, useEffect } from "react";
import Loading from "../components/Loading";
import { displayDangerNotif } from "../utils/NotificationProvider";

interface Config {
  apiUrl: string;
  textLogo: string;
}

interface ConfigContextProps {
  children: React.ReactNode;
}

const initialConfig: Config = {
  apiUrl: "",
  textLogo: "My App",
};

export const ConfigContext = createContext<Config>(initialConfig);

export const ConfigProvider: React.FC<ConfigContextProps> = ({ children }) => {
  const [config, setConfig] = useState<Config | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched config:");
        setConfig(data);
        localStorage.setItem("config", JSON.stringify(data));
        setIsLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
        displayDangerNotif("Critical Error", "Could not fetch config file.");
      });
  }, []);

  return (
    <ConfigContext.Provider value={config as Config}>
      {!isLoading ? children : <Loading />}
    </ConfigContext.Provider>
  );
};
