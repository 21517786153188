import React from "react";

interface Props {}

const ManageRush: React.FC<Props> = (props) => {
  return (
    <div>
      <p>Coming soon!</p>
    </div>
  );
};

export default ManageRush;
