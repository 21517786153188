import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import axios from "axios";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../utils/NotificationProvider";
import { cleanRegisterFormErrorKeys, displayFormErrors } from "../../../utils/FormValidation";

const useRegister = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [registrationCode, setRegistrationCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loginUser, logoutUser } = React.useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    logoutUser();
  }, [logoutUser]);

  const registerUser = async () => {
    try {
      const response = await axios.post("/register/", {
        username: username,
        password: password,
        code: registrationCode,
      });

      return true;
    } catch (e: any) {
      if (e.response.status === 400) {
        displayFormErrors(e, cleanRegisterFormErrorKeys);
      } else {
        displayDangerNotif(
          "Something went wrong.",
          "Please try again later or contact support."
        );
      }
      return false;
    }
  };

  const register = async (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    if (password !== confirmPassword) {
      displayDangerNotif("Passwords do not match.", "Please try again.");
      setIsLoading(false);
      return;
    }

    const isRegistered = await registerUser();

    if (!isRegistered) {
      setIsLoading(false);
      return;
    }

    await loginUser(username, password);
    displaySuccessNotif("Account Created", `Welcome, ${username}!`);
    navigate("/");

    setIsLoading(false);
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    registrationCode,
    setRegistrationCode,
    isLoading,
    register,
  };
};

export default useRegister;
