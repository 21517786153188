import React from "react";

const Dashboard: React.FC = () => {
  return (
    <>
      <h2 className="text-3xl font-semibold mb-6 text-center">Dashboard</h2>
      <div className="bg-white p-6 rounded shadow w-full lg:w-2/3 mx-auto">
        <p>
          Welcome to the Admin Panel. Select an option from the sidebar to get
          started.
        </p>
      </div>
    </>
  );
};

export default Dashboard;
