import React from "react";
import { FaCheck } from "react-icons/fa";

interface ContentDialogueProps {
  component: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}

const ContentDialogue: React.FC<ContentDialogueProps> = ({
  component,
  isOpen,
  onClose,
}) => {
  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 shadow-xl w-3/4 relative">
        {component}
        <button onClick={onClose} className="absolute bottom-4 right-4 text-white rounded-full p-2">
          <FaCheck color="gray" size={24} />
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ContentDialogue;
