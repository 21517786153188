import React, { createContext, useState, useEffect, ReactNode } from "react";
import axios from "axios";
import { displayDangerNotif } from "../utils/NotificationProvider";
import { Geed } from "../types/Geed";

interface GeedsContextProps {
  geeds: number[] | null;
  setGeeds: React.Dispatch<React.SetStateAction<number[] | null>>;
  populateGeeds: (
    orderBy: string,
    byDropped: boolean,
    searchTerm: string
  ) => void;
  isLoading: boolean;
}

interface GeedsProviderProps {
  children: ReactNode;
}

const clearHomeCache = () => {
  sessionStorage.removeItem("scrollY");
  sessionStorage.removeItem("previews");
  sessionStorage.removeItem("currentIndex");
  sessionStorage.removeItem("searchTerm");
};

export const GeedsContext = createContext<GeedsContextProps>({
  geeds: null,
  setGeeds: () => {},
  populateGeeds: (orderBy: string, byDropped: boolean) => {},
  isLoading: false,
});

// Extracts first and last name from search term based on spaces.
// If more than two spaces append the rest to the last name.
function extractNameFromSearchTerm(searchTerm: string): {
  first_name: string;
  last_name: string;
} {
  const nameParts = searchTerm.split(" ");
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");
  return { first_name: firstName, last_name: lastName };
}

export const GeedsProvider: React.FC<GeedsProviderProps> = ({ children }) => {
  const [geeds, setGeeds] = useState<number[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const populateGeeds = (
    orderBy: string,
    byDropped: boolean,
    searchTerm: string
  ) => {
    setIsLoading(true);

    axios
      .get("/geeds/", {
        params: {
          ordering: orderBy,
          by_dropped: byDropped,
          ...extractNameFromSearchTerm(searchTerm),
        },
      })
      .then((response) => {
        const data = response.data as number[];
        setGeeds(data);
        setIsLoading(false);
        // If geed context changes, the Home cache is invalidated.
        clearHomeCache();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status !== 401) {
          displayDangerNotif("Error", JSON.stringify(err.response));
        }
        console.warn(err);
      });
  };

  return (
    <GeedsContext.Provider
      value={{ geeds, setGeeds, populateGeeds, isLoading }}
    >
      {children}
    </GeedsContext.Provider>
  );
};
