import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaThumbsUp, FaThumbsDown, FaRegEye } from "react-icons/fa";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Loading from "../../components/Loading";
import ConfirmDialog from "../../components/ConfirmDialogue";
import AuthContext from "../../context/AuthContext";
import GeedImage from "./components/GeedImage";
import CommentSection from "./components/CommentSection";
import { useGeedActions } from "./hooks/useGeedActions";
import ContentDialogue from "../../components/ContentDialogue";
import axios from "axios";
import { formatPhoneNumber } from "../../utils/formatters";
import ModalLoader from "../../components/ModalLoader";
import SpringIn from "../../components/animations/SpringIn";
import { motion } from "framer-motion";

const GeedView: React.FC = () => {
  const params = useParams();
  const id = params["id"]!;
  const orderBy = params["orderby"]!;
  const byDropped = params["isdropped"]!;
  const { user } = React.useContext(AuthContext);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isViewLikesDislikesOpen, setIsViewLikesDislikesOpen] = useState(false);
  const [showDelayedLoader, setShowDelayedLoader] = useState(false);
  const [likersDislikers, setLikersDislikers] = useState<{
    likers: string[];
    dislikers: string[];
  }>({ likers: [], dislikers: [] });

  const {
    onLike,
    onDislike,
    onToggleDrop,
    navigateToGeed,
    getPreviousGeed,
    getNextGeed,
    isLoading,
    currentGeed,
    setCurrentGeed,
    isLikingDisliking,
    isFetching,
  } = useGeedActions(id, orderBy, byDropped);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isFetching) {
      timer = setTimeout(() => setShowDelayedLoader(true), 500);
    } else {
      setShowDelayedLoader(false);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  const NavigationButtons = () => (
    <div className="flex justify-between m-5">
      <button
        onClick={() => navigateToGeed(getPreviousGeed())}
        className="px-4 py-2 mb-4 bg-gray-300 rounded-full transition duration-500 ease-in-out transform hover:-translate-x-1 hover:scale-110"
      >
        <GrLinkPrevious size={25} color="black" />
      </button>
      <button
        onClick={() => navigateToGeed(getNextGeed())}
        className="px-4 py-2 mb-4 bg-gray-300 rounded-full transition duration-500 ease-in-out transform hover:translate-x-1 hover:scale-110"
      >
        <GrLinkNext size={24} color="black" />
      </button>
    </div>
  );

  const buttonVariants = {
    tap: { scale: 0.8, rotate: -10 },
    hover: { scale: 1.1, rotate: 10 },
  };

  const LikeDislikeButtons = () => (
    <div className="flex justify-end mt-2">
      <button
        onClick={() => {
          axios.get(`/geed/get/likers-dislikers/${id}`).then((response) => {
            setLikersDislikers(response.data);
            setIsViewLikesDislikesOpen(true);
          });
        }}
        className="p-2 rounded-full"
      >
        <FaRegEye color="gray" size={24} />
      </button>

      <motion.button
        variants={buttonVariants}
        whileTap="tap"
        whileHover="hover"
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
        onClick={onLike}
        className="p-2 rounded-full"
        disabled={isLikingDisliking}
      >
        <FaThumbsUp
          color={currentGeed!.liked_by_user ? "blue" : "gray"}
          size={24}
        />
      </motion.button>
      <motion.button
        variants={buttonVariants}
        whileTap="tap"
        whileHover="hover"
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
        onClick={onDislike}
        className="p-2 rounded-full"
        disabled={isLikingDisliking}
      >
        <FaThumbsDown
          color={currentGeed!.disliked_by_user ? "red" : "gray"}
          size={24}
        />
      </motion.button>
    </div>
  );

  const GeedInfo = () => (
    <div className="flex flex-col mb-4">
      <div>
        <h2 className="text-xl font-semibold">{`${currentGeed!.first_name} ${
          currentGeed!.last_name
        } ${
          user && user.isSuperUser
            ? formatPhoneNumber(currentGeed!.phone_number)
            : ""
        }`}</h2>
      </div>

      <div className="flex flex-row justify-between">
        <div className="flex-col">
          <p className="text-gray-600">Likes: {currentGeed!.like_count}</p>
          <p className="text-gray-600">
            Dislikes: {currentGeed!.dislike_count}
          </p>
        </div>
        <div className="flex-col">
          <p className="text-gray-600">Major: {currentGeed!.major}</p>
          <p className="text-gray-600">Year: {currentGeed!.school_year}</p>
        </div>
      </div>
    </div>
  );

  const DropButton = () => (
    <button
      onClick={() => {
        setIsConfirmDialogOpen(true);
      }}
      className="mt-2 bg-red-500 text-white py-2 px-4 rounded shadow-sm"
    >
      {currentGeed!.is_dropped ? "Undrop" : "Drop"}
    </button>
  );

  const LikeDislikeList: React.FC = () => {
    return (
      <div className="flex flex-col p-5 items-center h-[85vh] overflow-y-auto m-4">
        <div className="w-full border-b border-gray-300 mb-4">
          <h2 className="text-center font-bold mb-2">Likes</h2>
          <ul>
            {likersDislikers.likers.map((person, index) => (
              <li key={index} className="mb-1">
                {person}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full">
          <h2 className="text-center font-bold mb-2">Dislikes</h2>
          <ul>
            {likersDislikers.dislikers.map((person, index) => (
              <li key={index} className="mt-1">
                {person}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  // Make it so the transition looks better when pressing next.
  return !isLoading && currentGeed ? (
    <SpringIn>
      <div className="container mx-auto bg-white p-6 rounded shadow-sm max-w-screen-xl min-h-screen flex flex-col">
        {showDelayedLoader && <ModalLoader />}
        <ContentDialogue
          isOpen={isViewLikesDislikesOpen}
          onClose={() => setIsViewLikesDislikesOpen(false)}
          component={<LikeDislikeList />}
        />
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          title={currentGeed!.is_dropped ? "Undrop" : "Drop"}
          message={`${currentGeed!.first_name} ${currentGeed!.last_name}`}
          onConfirm={() => {
            onToggleDrop();
            setIsConfirmDialogOpen(false);
          }}
          onCancel={() => {
            setIsConfirmDialogOpen(false);
          }}
        />
        <GeedImage photo={currentGeed.photo} />
        {user && user.isSuperUser && <DropButton />}
        <NavigationButtons />
        <LikeDislikeButtons />
        <GeedInfo />
        <CommentSection geed={currentGeed} setGeed={setCurrentGeed} />
      </div>
    </SpringIn>
  ) : (
    <Loading />
  );
};

export default GeedView;
