import React from "react";

const Loading = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-12 h-12 border-t-4 border-red-500 border-solid rounded-full animate-spin"></div>
    </div>
  );
};

export default Loading;
