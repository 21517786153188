import React, { useEffect } from "react";
import CONSTANTS from "../../utils/constants";
import { InView } from "react-intersection-observer";
import Loading from "../../components/Loading";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import useGeeds from "./hooks/useGeeds";
import OrderBy from "./enums/OrderBy";
import { Geed } from "../../types/Geed";
import Fuse from "fuse.js";
import axios from "axios";
import { displayDangerNotif } from "../../utils/NotificationProvider";
import ButtonLoading from "../../components/ButtonLoading";
import { GeedPreview } from "../../types/Geed";
import { AiOutlineClose } from "react-icons/ai";
import debounce from "lodash.debounce";
import { motion } from "framer-motion";

const Home: React.FC = () => {
  const {
    isShowDropped,
    setIsShowDropped,
    searchTerm,
    setSearchTerm,
    orderBy,
    setOrderBy,
    geeds,
    populateGeeds,
    isLoading,
    viewGeed,
    fetchMorePreviews,
    currentIndex,
    previews,
    isFetchingPreview,
  } = useGeeds();

  const debounceSearchTerm = React.useCallback(
    debounce((value) => setSearchTerm(value), 500),
    []
  );

  React.useEffect(() => {
    return () => {
      debounceSearchTerm.cancel();
    };
  }, [debounceSearchTerm]);

  const GeedCard: React.FC<{
    person: GeedPreview;
  }> = React.memo(({ person }) => (
    <motion.div
      className="border border-gray-300 p-4 rounded shadow-sm bg-white cursor-pointer"
      onClick={() => viewGeed(person.id)}
      key={person.id}
      whileHover={{
        scale: 1.02,
        boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.1)",
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <InView as="div" key={person.id} triggerOnce>
        {({ inView, ref }) => (
          <img
            ref={ref}
            src={inView && person.thumbnail ? `${person.thumbnail}` : undefined}
            alt={`${person.first_name} ${person.last_name}`}
            className={`w-full h-32 object-cover rounded-md transition-opacity duration-500 ${
              inView ? "opacity-100" : "opacity-0"
            }`}
          />
        )}
      </InView>

      <div className="flex justify-between items-center p-4 bg-white shadow-md rounded-lg overflow-hidden">
        <h2 className="text-xl font-semibold">{`${person.first_name} ${person.last_name}`}</h2>
        <div className="flex items-center space-x-4">
          <button className="flex items-center space-x-1 text-gray-700 ">
            <FaThumbsUp size="1em" />
            <span className="text-lg">{person.like_count}</span>
          </button>
          <button className="flex items-center space-x-1 text-gray-700">
            <FaThumbsDown size="1em" />
            <span className="text-lg">{person.dislike_count}</span>
          </button>
        </div>
      </div>
    </motion.div>
  ));

  return (
    <div className="container mx-auto bg-white p-6">
      {geeds && !isLoading && (
        <p className="mt-6 text-center text-red-600">{`(${geeds!.length} ${
          !isShowDropped ? "PNMS" : "Dropped"
        })`}</p>
      )}
      <div className="my-4 flex justify-space-between space-x-4">
        <input
          type="text"
          placeholder="Firstname Lastname"
          className="flex-1 p-2 border border-gray-300 rounded shadow-sm"
          defaultValue={searchTerm}
          onChange={(e) => {
            debounceSearchTerm(e.target.value);
          }}
        />
        <select
          value={orderBy}
          className="flex-1 w-1/4 p-2 border border-gray-300 rounded shadow-sm"
          onChange={(event) => {
            localStorage.setItem("orderBy", event.target.value);
            setOrderBy(event.target.value);
          }}
        >
          <option value={OrderBy.default}>{"Oldest -> Newest"}</option>

          <option value={OrderBy.timeDecreasing}>{"Newest -> Oldest"}</option>

          <option value={OrderBy.likesDecreasing}>
            {"Most Liked -> Least Liked"}
          </option>
          <option value={OrderBy.likesIncreasing}>
            {"Least Liked -> Most Liked"}
          </option>
          <option value={OrderBy.dislikesDecreasing}>
            {"Most Disiked -> Least Disliked"}
          </option>
          <option value={OrderBy.dislikesIncreasing}>
            {"Least Disiked -> Most Disliked"}
          </option>
        </select>
        <div className="flex flex-col align items-center justify-center space-y-3">
          <input
            className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 checked:bg-red-500 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:after:bg-red-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={isShowDropped}
            onChange={() => {
              setIsShowDropped((prev) => !prev);
              localStorage.setItem(
                "isShowDropped",
                JSON.stringify(!isShowDropped)
              );
            }}
          />
          <label
            className="inline-block pl-[0.15rem] hover:cursor-pointer"
            htmlFor="flexSwitchCheckDefault"
          >
            {isShowDropped ? "Show PNMS" : "Show Dropped"}
          </label>
        </div>
      </div>

      {!isLoading ? (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {geeds &&
              previews.map((person: GeedPreview, index) => {
                return <GeedCard key={index} person={person} />;
              })}
          </div>
          {geeds && previews.length < geeds!.length && (
            <InView
              as="div"
              onChange={(inView) => {
                if (inView && !isFetchingPreview) fetchMorePreviews();
              }}
            >
              {isFetchingPreview && <span>Loading more...</span>}
            </InView>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Home;
