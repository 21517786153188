import React, { useEffect, useState } from "react";
import ManageItems, { ItemPreview } from "./components/ManageItem";
import { CiCirclePlus } from "react-icons/ci";
import axios from "axios";
import CreateRegistrationCodeModal from "./components/CreateRegistrationCodeModal";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../utils/NotificationProvider";

interface RegistrationCode {
  id: number;
  code: string;
  date_created: string;
  usage_count: number;
}

const ManageRegistrationCodes: React.FC = ({}) => {
  const [registrationCodes, setRegistrationCodes] = useState<
    RegistrationCode[]
  >([]);
  const [itemPreviews, setItemPreviews] = useState<ItemPreview[]>([]);
  const [isCreatingCode, setIsCreatingCode] = useState<boolean>(false);

  useEffect(() => {
    axios.get("/codes/get/").then((res) => {
      const registrationCodes = res.data as RegistrationCode[];
      setRegistrationCodes(registrationCodes);

      const itemPreviews = registrationCodes.map((registrationCode) => {
        const { code, id, date_created, usage_count } = registrationCode;
        return {
          name: `${code} (${usage_count})`,
          id,
          timestamp: date_created,
          selected: false,
        };
      });

      setItemPreviews(itemPreviews);
    }).catch((e) => {displayDangerNotif("Error", "Failed to fetch registration codes")})
  }, []);

  const createCode = (code: string, clearCode: () => void) => {
    axios
      .post("/codes/create/", {
        code: code,
      })

      .then((res) => {
        registrationCodes.push(res.data as RegistrationCode);
        itemPreviews.push({
          name: `${code} (0)`,
          id: res.data.id,
          timestamp: res.data.date_created,
          selected: false,
        });
        displaySuccessNotif(
          "Success",
          "Successfully created registration code"
        );
        setIsCreatingCode(false);
        clearCode();
      })
      .catch((err) => {
        displayDangerNotif("Error", "Failed to create registration code");
      })
  };

  return (
    <div className="">
      <CreateRegistrationCodeModal
        isOpen={isCreatingCode}
        onCreate={createCode}
        onCancel={() => setIsCreatingCode(false)}
      />
      <div className="flex flex-row justify-end mr-10">
        <button onClick={() => setIsCreatingCode(true)}>
          <CiCirclePlus size={30} color="blue" />
        </button>
      </div>
      <ManageItems
        title="Registration Codes"
        items={itemPreviews}
        setItems={setItemPreviews}
        deleteItemEndpoint="/codes/delete/"
        handleEditSelectedItem={() => {
          displayDangerNotif("Error", "You may not edit registration codes.");
        }}
      />
      {itemPreviews.length === 0 && (
        <p>Create a registration code to allow users to create an account!</p>
      )}
    </div>
  );
};

export default ManageRegistrationCodes;
