import axios from "axios";
import React, { useEffect, useState } from "react";
import { displayDangerNotif } from "../../../utils/NotificationProvider";
import EditUser from "./components/EditUser";
import ManageItem, { ItemPreview } from "./components/ManageItem";

interface User {
  username: string;
  id: number;
  date_joined: string;
}

const ManageUsers: React.FC = () => {
  const [currentEditUsername, setCurrentEditUsername] = useState<string | null>(
    null
  );
  const [currentEditID, setCurrentEditID] = useState<number>(-1);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [users, setUsers] = useState<ItemPreview[]>([]);
  const [itemPreviews, setItemPreviews] = useState<ItemPreview[]>([]);

  useEffect(() => {
    axios
      .get("/users/get/")
      .then((res) => {
        setUsers(res.data);
        setItemPreviews(usersToItemPreviews(res.data));
      })
      .catch((err) => {
        displayDangerNotif("Error", "Failed to fetch items.");
      });
  }, []);

  const usersToItemPreviews = (items: User[]): ItemPreview[] => {
    return items.map((item) => {
      const { username, date_joined, ...otherProps } = item;
      return {
        name: username,
        timestamp: date_joined,
        selected: false,
        ...otherProps,
      };
    });
  };

  const handleEditUser = (user: ItemPreview) => {
    setCurrentEditUsername(user.name);
    setCurrentEditID(user.id);
    setIsEditingUser(true);
  };
  return (
    <div className="">
      <EditUser
        initialUsername={currentEditUsername}
        isOpen={isEditingUser}
        onClose={() => {
          setIsEditingUser(false);
        }}
        id={currentEditID}
      />
      <ManageItem
        title="Manage Users"
        items={itemPreviews}
        setItems={setItemPreviews}
        deleteItemEndpoint="/users/delete/"
        handleEditSelectedItem={handleEditUser}
      />
    </div>
  );
};

export default ManageUsers;
