import React from "react";
import Login from "./pages/Login/Login";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import Home from "./pages/Home/Home";
import GeedView from "./pages/GeedView/GeedView";
import { GeedsProvider } from "./context/GeedContext";
import EntryView from "./pages/EntryView/EntryView";
import { Toaster } from "react-hot-toast";
import Register from "./pages/Register/Register";
import AdminView from "./pages/AdminView/AdminView";
import { ConfigProvider } from "./context/ConfigContext";

const App = () => {
  return (
    <Router>
      <Toaster />
      <ConfigProvider>
        <AuthProvider>
          <GeedsProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Home />
                  </AuthRoute>
                }
              />
              <Route
                path="/geedview/:isdropped/:orderby/:id"
                element={
                  <AuthRoute>
                    <GeedView />
                  </AuthRoute>
                }
              />
              <Route
                path="/entry"
                element={
                  <AuthRoute>
                    <EntryView />
                  </AuthRoute>
                }
              />
              <Route
                path="/admin/*"
                element={
                  <AuthRoute>
                    <AdminView />
                  </AuthRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
          </GeedsProvider>
        </AuthProvider>
      </ConfigProvider>
    </Router>
  );
};

export default App;
