import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Geed } from "../../../types/Geed";
import { GeedsContext } from "../../../context/GeedContext";
import {
  displayDangerNotif,
  displaySuccessNotif,
} from "../../../utils/NotificationProvider";

export const useGeedActions = (
  id: string,
  orderBy: string,
  byDropped: string
) => {
  const navigate = useNavigate();
  const { geeds, setGeeds, populateGeeds, isLoading } =
    React.useContext(GeedsContext);
  const [index, setIndex] = useState<number>(-1);
  const [currentGeed, setCurrentGeed] = useState<Geed | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLikingDisliking, setIsLikingDisliking] = useState<boolean>(false);
  const isDropped = byDropped === "dropped";

  const fetchGeed = (id: number) => {
    setIsFetching(true);
    axios
      .get(`/geed/${id}/`, {})
      .then((response) => {
        console.log(response.data);
        setCurrentGeed(response.data as Geed);
      })
      .catch((e) => {
        displayDangerNotif("Error", "There was an error fetching this PNM.");
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  // TODO: Figure out how to preserve search term in url.
  // Switch to query params entirely no slash bullshit.
  useEffect(() => {
    if (geeds === null) {
      populateGeeds(orderBy!, isDropped, "");
    }
  }, []);

  const prevId = useRef<number>(-1);
  useEffect(() => {
    if (!geeds) {
      return;
    }

    setIndex(geeds!.findIndex((item) => item === parseInt(id)));
    // Avoid fetching comments if we are on the same geed.
    prevId.current = parseInt(id);

    fetchGeed(parseInt(id));
  }, [geeds, id]);

  useEffect(() => {
    if (geeds != null && index !== -1) {
      fetchGeed(geeds[index]);
    }
  }, [index]);

  const onLike = () => {
    setIsLikingDisliking(true);
    axios
      .post("/like/", {
        id: id,
      })
      .then((response) => {
        setCurrentGeed((geed) => {
          if (geed === null) return null;
          return {
            ...geed,
            liked_by_user: !geed.liked_by_user,
            like_count: geed.liked_by_user
              ? geed.like_count - 1
              : geed.like_count + 1,
            dislike_count: geed.disliked_by_user
              ? geed.dislike_count - 1
              : geed.dislike_count,
            disliked_by_user: false,
          };
        });
      })
      .catch((e) => {
        displayDangerNotif("Error", "There was an error liking this PNM.");
      })
      .finally(() => {
        setIsLikingDisliking(false);
      });
  };

  const onDislike = () => {
    // Dislike logic here
    setIsLikingDisliking(true);
    axios
      .post("/dislike/", {
        id: id,
      })
      .then((response) => {
        setCurrentGeed((geed) => {
          if (geed === null) return null;
          return {
            ...geed,
            liked_by_user: false,
            like_count: geed.liked_by_user
              ? geed.like_count - 1
              : geed.like_count,
            dislike_count: geed.disliked_by_user
              ? geed.dislike_count - 1
              : geed.dislike_count + 1,
            disliked_by_user: !geed.disliked_by_user,
          };
        });
      })
      .catch((e) => {
        displayDangerNotif("Error", "There was an error disliking this PNM.");
      })
      .finally(() => {
        setIsLikingDisliking(false);
      });
  };

  const onToggleDrop = () => {
    // Drop/Undrop logic here
    axios
      .post("/pnms/toggle-drop/", { id: id })
      .then((response) => {
        const previousGeedID = getPreviousGeed();
        const nextGeedID = getNextGeed();
        const name = `${currentGeed?.first_name} ${currentGeed?.last_name}`;
        const geedsCopy = [...geeds!];
        geedsCopy.splice(index, 1);
        setGeeds(geedsCopy);

        if (geedsCopy.length === 0) {
          navigate("/");
        }

        // If we remove last element index wont exist.
        if (index === geeds!.length - 1) {
          navigateToGeed(previousGeedID);
        }

        navigateToGeed(nextGeedID);

        if (isDropped) {
          displaySuccessNotif("Undropped", `${name}`);
        } else {
          displaySuccessNotif("Dropped", `${name}`);
        }
      })
      .catch((e) => {
        console.error("Failed drop!");
      });
  };

  const navigateToGeed = (id: number) => {
    navigate(`/geedview/${byDropped}/${orderBy}/${id}`);
  };

  const getPreviousGeed = (): number => {
    return geeds![(index - 1 + geeds!.length) % geeds!.length];
  };

  const getNextGeed = (): number => {
    return geeds![(index + 1) % geeds!.length];
  };

  return {
    onLike,
    onDislike,
    onToggleDrop,
    navigateToGeed,
    getPreviousGeed,
    getNextGeed,
    isLoading,
    geeds,
    index,
    currentGeed,
    setCurrentGeed,
    isLikingDisliking,
    isFetching,
  };
};
